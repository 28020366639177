/// <reference types="mapbox" />

import type { LatLngBounds, Map as LeafletMap } from "leaflet";
import type {
  ProgramRefWithLocation,
  SearchParams,
} from "~/types/drupal_jsonapi";

import {
  createEffect,
  createUniqueId,
  DEV,
  onCleanup,
  onMount,
  type Ref,
  Show,
} from "solid-js";

import { createScriptLoader } from "@solid-primitives/script-loader";
import { Link } from "@solidjs/meta";
import { createMarkerIconWithImage, mapMarkerImage } from "~/utils/map_utils";
import CardInfoWindow from "~/components/Cards/CardInfoWindow";
import { useMapContext } from "~/contexts/MapContext";

const mapboxStyle = "mapbox://styles/breek/cjk2o9xhz06dr2rogxjl0wb9w";
const accessToken =
  "pk.eyJ1IjoiYnJlZWsiLCJhIjoiY2poc3hraW9xMDc5NTNrbjN1dzR4OW5kZCJ9.OEPAJ6nuIJIPu8_zd8IMOQ";

let map: Ref<HTMLDivElement | LeafletMap | null>;
let activeMarker: Ref<HTMLDivElement>;

const mapID = createUniqueId();

const markers: Record<number, L.Marker> = {};

export default function Map(props: {
  programs: ProgramRefWithLocation[];
  searchParams?: SearchParams;
}) {
  const [mapCtx, { setMarkerNid, unsetMarkerNid }] = useMapContext();

  onMount(() => {
    //setHasMapboxRequested!(true);
    createScriptLoader({
      onload: () => {
        DEV && console.log("Mapbox main lib loaded");
        L.mapbox.accessToken = accessToken;
        createScriptLoader({
          onload: () => {
            DEV && console.log("Leaflet gesture loaded");
            setTimeout(() => {
              initMap(props.programs, setMarkerNid);
            }, 600);
          },
          src: "//unpkg.com/leaflet-gesture-handling@1.1.8/dist/leaflet-gesture-handling.min.js",
        });
      },
      src: "//api.mapbox.com/mapbox.js/v3.1.1/mapbox.js",
    });
  });

  onCleanup(() => unsetMarkerNid!());

  createEffect(() => {
    if (mapCtx.values.nid) {
      markers[mapCtx.values.nid] && markers[mapCtx.values.nid].openPopup();
    }
  });

  return (
    <>
      <Link
        rel="stylesheet"
        href="https://api.mapbox.com/mapbox.js/v3.1.1/mapbox.css"
      />
      <div
        id={mapID}
        ref={map as HTMLDivElement}
        class="map-results"
        data-ga-zone="map"
        data-test="map"
        style={{ height: "100%", width: "100%" }}
      />
      <div ref={activeMarker as HTMLDivElement}>
        <Show when={mapCtx.values.nid} keyed>
          <CardInfoWindow
            nid={mapCtx.values.nid!}
            searchParams={props.searchParams}
          />
        </Show>
      </div>
    </>
  );
}

function initMap(
  programs: ProgramRefWithLocation[],
  setMarkerNid?: (nid: number) => void,
) {
  // Default center on Cogedim
  const center = L.latLng(48.8733559, 2.3125263);
  const zoom = 17;

  map = L.mapbox
    .map(mapID, "mapbox.streets", {
      attributionControl: false,
      scrollWheelZoom: false,
      zoomControl: false,
      maxZoom: 17,
    })
    .setView(center, zoom) as unknown as LeafletMap;

  L.mapbox.styleLayer(mapboxStyle).addTo(map as unknown as LeafletMap);
  new L.Control.Zoom({ position: "bottomleft" }).addTo(
    map as unknown as LeafletMap,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const markerBounds: LatLngBounds = L.latLngBounds();

  // Add markers
  const icon = createMarkerIconWithImage(mapMarkerImage);
  for (const p of programs) {
    const point = L.latLng(p.gps.lat, p.gps.lon);

    const marker = L.marker(point, {
      icon: icon,
      alt: p.id_pp_program,
      title: p.title,
    }).addTo(map as unknown as LeafletMap);
    marker.bindPopup(activeMarker as HTMLDivElement);
    marker.on("popupopen", () => {
      setMarkerNid!(p.id);
    });

    markers[p.id] = marker;
    markerBounds.extend(point);
  }

  map.fitBounds(markerBounds);
}
